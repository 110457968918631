import { useEffect, useState } from "react";
import useSocket from "../../../../hook/useSocket";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Layouts/Loader";
import io_path from "../../../../io_path";

export default function GamePage() {
    const [gameData, setGameData] = useState(null);
    const { socket } = useSocket();
    const { history_game_id = null } = useParams();
    const { state: locationState = null } = useLocation();
    const navigate = useNavigate();
    console.log(gameData);

    useEffect(() => {
        const isLoadAdditionalData = !!locationState;
        if (isLoadAdditionalData) setGameData(locationState);

        socket.emit(io_path.ONE_RECENT_GAME_FULL_STAT, {
            game_id: history_game_id,
            additional: isLoadAdditionalData,
        });

        socket.on(io_path.ONE_RECENT_GAME_FULL_STAT, (data) => {
            setGameData(
                isLoadAdditionalData ? (prev) => ({ ...prev, ...data }) : data
            );
        });

        return () => {
            socket.off(io_path.ONE_RECENT_GAME_FULL_STAT);
        };
    }, []);

    if (!gameData) return <Loader />;
    const showId = gameData?.game_outer_id
        ? gameData?.game_outer_id
        : gameData?.game_id;

    return (
        <div className="go_game game_page">
            <div className="content">
                <div className="p_head">
                    <h1
                        onClick={() => {
                            navigate("../", { replace: true });
                        }}
                    >
                        <div className="back_arrow" />
                        Lottery ID: {showId}
                    </h1>
                </div>
                <div className="lottery_info">
                    <div className="row">
                        <h1>Big Lottery</h1>
                        <div className="id">ID: {showId}</div>
                    </div>
                    <div className="row">
                        <div className="text">
                            Total tickets sold:
                            <span>
                                {gameData?.all_players_tickets_count ?? "..."}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="text">
                            Users in game:{" "}
                            <span>{gameData.players_in_game}</span>
                        </div>
                    </div>
                    <div className="jackpot">
                        <div className="text">Jackpot</div>
                        <div className="value">{gameData.jackpot}</div>
                    </div>
                </div>
                <div className="lucky_numbers">
                    <div className="l_n_title">Lucky numbers</div>
                    <div className="container">
                        {renderLuckyNumbers(gameData.win_numbers)}
                    </div>
                </div>
                <div className="win-info">
                    <div className="block">
                        <div className="title">Winning ticket</div>

                        <div className={`value`}>
                            {gameData.my_winn_tick_count}
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">Your Prize:</div>
                        <div className={`value `}>{gameData.my_total_win}</div>
                    </div>
                </div>
                <div className="tickets_btn_container">
                    <div className="text">
                        Total tickets purchased:{" "}
                        {gameData?.total_bought_tickets ?? "..."}
                    </div>
                    <button className={"show"} onClick={goCheckAllTickets}>
                        View all tickets
                    </button>
                </div>
            </div>
        </div>
    );

    function goCheckAllTickets() {
        // console.log(gameData?.total_bought_tickets);
        if (!gameData?.total_bought_tickets) return;
        navigate("./my-tickets", {
            replace: false,
            state: {
                total_tickets: gameData.total_bought_tickets,
                win_numbers: gameData.win_numbers,
                my_total_win: gameData.my_total_win,
                win_tickets: gameData.my_winn_tick_count,
            },
        });
    }

    function renderLuckyNumbers(nums = new Array(6).fill("")) {
        return nums.map((num, index) => (
            <div key={index} className="lucky_number number show">
                {num}
            </div>
        ));
    }
}
