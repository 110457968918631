import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useSocket from "../../../hook/useSocket";
import SelectSettings from "./SelectSettings";
import FillTickets from "./FillTickets";
import { getRandomNums } from "../../../utils/helpers";
import Loader from "../../Layouts/Loader";
import io_path from "../../../io_path";

export default function BuyTickets() {
    const [showSettings, setShowSettings] = useState(true);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [ticketsCount, setTicketsCount] = useState(1);
    const [fillType, setFillType] = useState(false); // true - auto fill, false - manual fill

    const [filledTickets, setFilledTickets] = useState([]);

    const { socket, gameSettings, gameInfo } = useSocket();
    const {
        settings: {
            tickets_per_user = null,
            ticket_cost = 10,
            commission = 10,
            total_tickets = null,
        },
    } = gameSettings;
    const { total_tickets_sold = 0 } = gameInfo;
    const navigate = useNavigate();

    useEffect(() => {
        // socket.emit("test", "b05f013c048f900a");
        socket.on(io_path.TICKETS_BOUGHT, (data) => {
            setLoading(false);
            socket.emit(io_path.CHECK_BALANCE);
            if (data.code > 0) {
                // console.log(data)
                setError(data.message);
                return;
            } else {
                socket.emit(io_path.GAME_INFO);
                socket.emit(io_path.GET_TICKETS);
                navigate("..");
            }
            console.log(data);
            // navigate(-1, { replace: true });
        });
        return () => {
            socket.off(io_path.TICKETS_BOUGHT);
        };
    }, []);

    // console.log(gameSettings);
    // console.log(gameInfo);
    // console.log(filledTickets);
    return (
        <div className="buy_tickets">
            <div className="content">
                {loading ? <Loader /> : ""}
                {showSettings ? (
                    <SelectSettings
                        ticketsCount={ticketsCount}
                        setTicketsCount={setTicketsCount}
                        fillType={fillType}
                        setFillType={setFillType}
                        goBuyTickets={goBuyTickets}
                        error={error}
                        setError={setError}
                    />
                ) : (
                    <FillTickets
                        filledTickets={filledTickets}
                        setFilledTickets={setFilledTickets}
                        setFillType={setFillType}
                        buyTickets={buyTickets}
                        setShowSettings={setShowSettings}
                    />
                )}
            </div>
        </div>
    );

    //autofill on server
    function buyTickets(fillType = true) {
        setLoading(true);
        // const tickets = filledTickets.map((ticket) => ticket.join(","));
        const data = {
            tickets: fillType ? [] : filledTickets,
            fillType,
            ticketsCount,
        };
        console.log(data);
        socket.emit(io_path.BUY_TICKETS, data);

        // });
    }

    // if be more right - go fill tickets
    function goBuyTickets() {
        if (fillType) return buyTickets(true);

        const add_blank_tickets = ticketsCount - filledTickets.length;
        setFilledTickets((prev) => [
            ...prev,
            ...Array(add_blank_tickets).fill([]),
        ]);
        setShowSettings(false);
    }
}
