import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useSocket from "../hook/useSocket";
import io_path from "../io_path";
import Prepare from "./Prepare";
import GameStart from "./Game";
import Loader from "./Layouts/Loader";
import Error from "./Layouts/Error";
import SocketDisconnect from "./Layouts/SocketDisconnect";

export default function StartPoint() {
    const [statusInit, setStatusInit] = useState({
        loading: true,
        error: false,
        error_msg: "",
    });
    const navigate = useNavigate();
    const { gameID, userToken } = useParams();

    const { connectToSocket, socket, gameSettings } = useSocket();

    useEffect(() => {
        if (!gameID || !userToken) {
            setStatusInit({
                loading: false,
                error: true,
                error_msg: "You have no auth data",
            });
        } else {
            init_game(gameID, userToken);
        }
    }, []);
    useEffect(() => {
        // console.log("Game settings in index check: ");
        // console.log(gameSettings);

        if (gameSettings && gameSettings.game_state >= 1) {
            console.log("go navigate now");
            navigate(`/${gameID}/${userToken}/`);
        }
    }, [gameSettings]);

    if (statusInit.loading && !gameSettings) return <Loader />;
    if (statusInit.error) return <Error msg={statusInit.error_msg} />;

    if (!socket) return <SocketDisconnect />;

    if (gameSettings.game_state < 1) return <Prepare />;
    return <GameStart />;

    async function init_game(game, token) {
        // TO DO : send with cookie settings ( to check other time session)
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/client/game/enter",
                {
                    game_id: game,
                    user_token: token,
                }
            );
            console.log(data);
            connectToSocket(data, () => {
                setStatusInit({
                    loading: false,
                    error: false,
                    error_msg: "",
                });
            });
        } catch (err) {
            console.log(err);
            setStatusInit({
                loading: false,
                error: true,
                error_msg: err.response.data.msg,
            });
        }
    }
}
