import { useEffect, useRef } from "react";
import useSocket from "../../hook/useSocket";
import { prettyTime } from "../../utils/helpers";

export default function Timer() {
    const { workerForTimer } = useSocket();
    const timerRef = useRef(null);

    useEffect(() => {
        // console.log("workerForTimer: ", !!workerForTimer);
        if (workerForTimer) {
            workerForTimer.addEventListener("message", workerFunction);

            // console.log(workerForTimer);
            // workerForTimer.removeEventListener("message");

            return () => {
                // workerForTimer.terminate();
                workerForTimer.removeEventListener("message", workerFunction);
            };
        }
    }, [workerForTimer]);

    return (
        <div className="timer">
            <h1>Next draw in:</h1>
            <div className="value" ref={timerRef}>
                00m:00s
            </div>
        </div>
    );

    function workerFunction(e) {
        if (!e) return;
        let { type, time_left } = e.data;
        if (type === "tick") {
            // console.log("time_left: ", time_left);
            const time = prettyTime(time_left);

            timerRef.current.innerText = `${
                +time.hours ? time.hours + "h" : ""
            }${time.minutes}m:${time.seconds}s`;
        } else if (type === "done") {
            timerRef.current.innerText = "00m:00s";
        }
    }
}
