import { createContext, useContext, useEffect, useState } from "react";
import * as socketIO from "socket.io-client";
import io_path from "../io_path";
import WebWorker from "../webWorkers/WebWorkers";
import timer_worker from "../webWorkers/timer.worker";
import { prettyTime } from "../utils/helpers";

const SocketContext = createContext(null);

export function SocketProvider({ children }) {
    const [socket, setSocket] = useState(undefined);

    const [gameRoom, setGameRoom] = useState([]);
    const [gameSettings, setGameSettings] = useState(null);
    const [gameInfo, setGameInfo] = useState({
        jackpot: 0,
        total_tickets: 0,
    });
    const [timer_to_start, setTimerToStart] = useState(null);
    const [workerForTimer, setWorkerForTimer] = useState(null);
    const [myTickets, setMyTickets] = useState({
        tickets: [],
        total_tickets: 0,
    });

    const [meLikeUser, setMeLikeUser] = useState(null);
    useEffect(() => {
        if (socket) {
            socket.emit(io_path.ROOM_CONNECT);

            socket.on(io_path.ROOM_CONNECT, (init_data) => {
                setGameRoom(init_data.room);
                setGameInfo(init_data.lottery_state);
                setTimerToStart(init_data.timer_to_start);
            });
            socket.on(io_path.ROOM_LEAVE, (userId) => {
                // console.log("leave room, user id: ", userId);
                setGameRoom((prev) => prev.filter((i) => i.id !== userId));
            });
            socket.on(io_path.GAME_INFO, (data) => {
                // console.log("game info:");
                // console.log(data);
                setGameInfo(data);
            });
            socket.on(io_path.GAME_STATE, (data) => {
                // console.log("GAME_STATE");
                // console.log(data);
                setGameSettings((prev) => ({ ...prev, ...data }));
            });
            socket.on(io_path.START_DRAW, (data) => {
                // console.log("START_DRAW");
                window.localStorage.setItem("start_draw_" + gameSettings.id, 1);
            });
            socket.on(io_path.CHECK_BALANCE, (data) => {
                // console.log("balance:");
                // console.log(data);
                setMeLikeUser((prev) => ({ ...prev, balance: data }));
            });
            socket.on(io_path.GET_TICKETS, (data) => {
                // console.log("get tickets:");
                // console.log(data);
                setMyTickets(data);
            });

            socket.on(io_path.USER_DATA, (data) => {
                // console.log("UPD USER DATA");
                setMeLikeUser((prev) => ({ ...prev, ...data }));
            });

            socket.on(io_path.ASK_RESULT, () => {
                // console.log("ask result");
                socket.emit(io_path.ASK_RESULT);
            });

            //test
            // socket.on("game_state", (data) => {
            //     console.log("change status");
            //     console.log(data);
            // });

            return () => {
                socket.off(io_path.ROOM_CONNECT);
                socket.off(io_path.GAME_INFO);
                socket.off(io_path.GAME_STATE);
                socket.off(io_path.CHECK_BALANCE);
                socket.off(io_path.GET_TICKETS);
                socket.off(io_path.START_DRAW);
                socket.off(io_path.ROOM_LEAVE);
                socket.off(io_path.USER_DATA);
                socket.off(io_path.ASK_RESULT);

                // socket.off("game_state");
            };
        }
    }, [socket]);
    useEffect(() => {
        if (timer_to_start && !workerForTimer) {
            const timer_webWorker = new WebWorker(timer_worker);
            timer_webWorker.postMessage({
                command: "startTimer",
                time: timer_to_start,
            });
            setWorkerForTimer(timer_webWorker);
        }
    }, [timer_to_start]);

    const val = {
        socket,
        connectToSocket,
        meLikeUser,
        gameRoom,
        gameSettings,
        gameInfo,
        workerForTimer,
        myTickets,
    };
    return (
        <SocketContext.Provider value={val}>{children}</SocketContext.Provider>
    );

    function connectToSocket({ token, user, game }, cb = false) {
        // console.log(process.env.REACT_APP_SERVER_URL);
        let socket = socketIO.connect(process.env.REACT_APP_SERVER_URL, {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
            reconnectionAttempts: 2,
        });

        socket.on("connect", () => {
            // console.log("connect!");
            setSocket(socket);
            setGameSettings(game);
            setMeLikeUser(user);
            if (cb) cb();
        });

        // socket.on(io_path.ROOM_CONNECTED, (data) => {
        //     // console.log(data);
        //     if (data?.type === "users") {
        //         // console.log(data.data);
        //         // console.log(user);

        //         // console.log(data.data);
        //         // setRoom(data.data); //.filter((i) => i.id != user.id)
        //         setRoom((prev) => {
        //             return data.data.map((item) => {
        //                 const a = prev.find((j) => j.id == item.id);
        //                 return {
        //                     ...a,
        //                     ...item,
        //                 };
        //             });
        //         });
        //     }
        //     if (data?.type === "player") {
        //         setUser((prev) => ({ ...prev, ...data.data }));
        //     }
        //     if (data?.type === "tickets") {
        //         setGame((prev) => ({ ...prev, ...data.data }));
        //     }
        //     if (data?.type === "users_room") {
        //         // console.log(data.data);
        //         setRoom((prev) => {
        //             return prev.map((item) => {
        //                 const a = data.data.find((j) => j.id == item.id);
        //                 return {
        //                     ...a,
        //                     ...item,
        //                 };
        //             });
        //         });
        //     }
        //     // setRoom((prev) => [...prev.filter((i) => i.id !== user.id), user]);
        // });

        // socket.on("connect_error", (error) => {
        //     // console.log(error);
        //     alert("socket connect error");
        // });

        socket.on("disconnect", (user) => {
            setSocket(false);
            socket.off("connect_error");
            // socket.off(io_path.ROOM_CONNECTED);
            socket.off("connect");
        });
    }
}

export default function useSocket() {
    return useContext(SocketContext);
}
