import { Route, Routes } from "react-router-dom";
import WaitingRoom from "./WaitingRoom";
import BuyTickets from "./BuyTickets";
import StatPage from "./StatPage";
import MyBoughtTicketsList from "./MyBoughtTicketsList";

export default function Prepare() {
    return (
        <Routes>
            <Route index element={<WaitingRoom />} />
            <Route path="/buy-tickets" element={<BuyTickets />} />
            <Route path="/my-stat/*" element={<StatPage />} />
            <Route
                path="/my-bought-tickets"
                element={<MyBoughtTicketsList />}
            />
        </Routes>
    );
}
