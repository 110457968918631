import { useEffect, useState } from "react";
import useSocket from "../../hook/useSocket";
import io_path from "../../io_path";
import { prettyAmount } from "../../utils/helpers";

export default function BiggestWin() {
    const [biggestWinData, setBiggestWinData] = useState(null);
    const { socket, gameRoom, gameSettings, gameInfo } = useSocket();
    useEffect(() => {
        socket.emit(io_path.BIGGEST_WIN);
        socket.on(io_path.BIGGEST_WIN, (data) => {
            console.log(data);
            setBiggestWinData(data);
        });

        return () => {
            socket.off(io_path.BIGGEST_WIN);
        };
    }, [socket]);

    // console.log(biggestWinData);
    if (biggestWinData === false)
        return (
            <div className="block last_game">
                <div className="row">
                    <h2>No Biggest winnings data</h2>
                </div>
            </div>
        );
    if (!biggestWinData) return <LoadingSkeleton />;
    return (
        <div className="block last_game">
            <div className="row">
                <h2>Biggest winnings</h2>
            </div>
            <div className="big-winners">
                <div className="holder">{renderWinners()}</div>
            </div>
        </div>
    );

    function renderWinners() {
        return biggestWinData.map((winner, index) => {
            return (
                <div key={index} className="big-winner">
                    <div className="top">
                        <div className="number">{index + 1}</div>
                        <div className="info">
                            <div className="b_head">Total Jackpot </div>
                            <div className="amount">
                                {prettyAmount(winner.win_amount, true)} coins
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="user">
                            Winner:
                            <div className="name">{winner.user}</div>
                        </div>
                    </div>
                </div>
            );
        });
    }
}

function LoadingSkeleton() {
    return (
        <div className="block last_game">
            <div className="row">
                <h2>Biggest winnings</h2>
            </div>
        </div>
    );
}
