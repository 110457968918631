import { useNavigate } from "react-router-dom";
import { ReactComponent as StatSvg } from "../../../svg/stat_btn.svg";
import useSocket from "../../../hook/useSocket";
import { useEffect, useState } from "react";
import io_path from "../../../io_path";
import Loader from "../../Layouts/Loader";
import { prettyAmount } from "../../../utils/helpers";
export default function MyStat() {
    const navigate = useNavigate();
    const { socket } = useSocket();
    const [totalStat, setTotalStat] = useState(null);
    const [recentGames, setRecentGames] = useState(null);
    const [recentGamesPagination, setRecentGamesPagination] = useState({
        limit: 5,
        page: 0,
    });
    const [show_more_btn, setShowMoreBtn] = useState(false);

    useEffect(() => {
        socket.emit(io_path.GET_TOTAL_STAT);
        socket.on(io_path.GET_TOTAL_STAT, (data) => {
            setTotalStat(data);
        });

        return () => {
            socket.off(io_path.GET_TOTAL_STAT);
        };
    }, []);
    useEffect(() => {
        if (totalStat && totalStat.totalTicketsBought > 0) {
            socket.emit(io_path.RECENT_GAMES, {
                limit: recentGamesPagination.limit,
                page: recentGamesPagination.page,
            });

            socket.on(io_path.RECENT_GAMES, (data) => {
                if (data.length < recentGamesPagination.limit) {
                    setShowMoreBtn(false);
                } else {
                    setShowMoreBtn(true);
                }
                setRecentGames((prev) => (prev ? [...prev, ...data] : data));
            });

            return () => {
                socket.off(io_path.RECENT_GAMES);
            };
        }
    }, [recentGamesPagination, totalStat]);

    // console.log(totalStat);
    if (!totalStat) {
        return <Loader />;
    }
    return (
        <div className="stat-page">
            <div className="content">
                <div className="head">
                    <h1>
                        <StatSvg />
                        My statistics
                    </h1>
                    <div
                        className="close"
                        onClick={() => {
                            navigate("..", { replace: true });
                        }}
                    />
                </div>
                <div className="total-stat">
                    <div className="stat">
                        <div className="s_title">Total tickets purchased:</div>
                        <div className="s_value">
                            {totalStat.totalTicketsBought}
                        </div>
                    </div>
                    <div className="stat">
                        <div className="s_title">Total winning tickets:</div>
                        <div className="s_value">
                            {totalStat.totalTicketsWin}
                        </div>
                    </div>
                </div>
                <div className="bl numbers_guessed">
                    <h2>Numbers guessed</h2>
                    <div className="holder">{renderGuessedBlock()}</div>
                </div>

                <div className="bl rec_lotts">
                    <h2>Recent lotteries:</h2>
                    <div className="games-holder">{renderRecentGames()}</div>
                    {show_more_btn && (
                        <button
                            className="load-more"
                            onClick={() => {
                                setRecentGamesPagination((prev) => ({
                                    ...prev,
                                    page: prev.page + 1,
                                }));
                            }}
                        >
                            Load more
                        </button>
                    )}
                </div>
            </div>
        </div>
    );

    function renderRecentGames() {
        if (!recentGames) {
            return new Array(4)
                .fill(0)
                .map((_, index) => <MiniLottery key={index} skeleton />);
        }
        return recentGames.map((game) => {
            const show_id = game?.game_outer_id
                ? game.game_outer_id
                : game.game_id;
            return (
                <MiniLottery
                    key={game.game_id}
                    full_data={game}
                    game_id={show_id}
                    lucky_numbers={game.win_numbers}
                    matches={game.my_win_matches}
                    prize={game.my_total_win}
                />
            );
        });
    }

    function renderGuessedBlock() {
        return new Array(4)
            .fill(0)
            .map((_, index) => index + 3)
            .sort((a, b) => b - a)
            .map((num) => {
                return (
                    <div key={num} className="number">
                        <div className="num">{numAsString(num)}</div>
                        <div className="count">
                            {totalStat.guessedNumbers[num]}
                        </div>
                    </div>
                );
            });

        function numAsString(num) {
            switch (num) {
                case 3:
                    return "Three";
                case 4:
                    return "Four";
                case 5:
                    return "Five";
                case 6:
                    return "Six";
                default:
                    return num;
            }
        }
    }
}

function MiniLottery({
    game_id = "",
    lucky_numbers = [],
    matches = 0,
    prize = 0,
    full_data = {},
    skeleton = false,
}) {
    const navigate = useNavigate();

    if (skeleton) {
        return (
            <div className="mini-lottery skeleton">
                <div className="head_id">
                    <div className="id" />
                </div>
                <div className="numbers-block">
                    <div className="hd" />
                    <div className="numbers">
                        {new Array(6).fill(0).map((_, index) => (
                            <div key={index} className="number" />
                        ))}
                    </div>
                </div>
                <div className="big_num">
                    <div className="hd" />
                    <div className="val" />
                </div>
                <div className="big_num">
                    <div className="hd" />
                    <div className="val" />
                </div>
                <div className="btn" />
            </div>
        );
    }
    return (
        <div className="mini-lottery">
            <div className="head_id">
                <div className="id">Lottery ID: {game_id}</div>
            </div>
            <div className="numbers-block">
                <div className="hd">Lucky numbers:</div>
                <div className="numbers">
                    {lucky_numbers.map((num, index) => (
                        <div key={index} className="number">
                            {num}
                        </div>
                    ))}
                </div>
            </div>
            <div className="big_num">
                <div className="hd">Matches:</div>
                <div className="val">{matches}</div>
            </div>
            <div className="big_num">
                <div className="hd">Prize:</div>
                <div className="val">{prettyAmount(prize, true)}</div>
            </div>
            <div
                className="btn"
                onClick={() =>
                    navigate("./" + full_data.game_id, { state: full_data })
                }
                // onClick={() => navigate("./" + full_data.game_id)}
            >
                View details
            </div>
        </div>
    );
}
