import { prettyAmount } from "../../utils/helpers";
import { ReactComponent as DoublePagArrSvg } from "../../svg/double_pag_arr.svg";
import { ReactComponent as PagArrSvg } from "../../svg/pag_arr.svg";

export default function TicketsList({
    pagination = false,
    changePagination = () => {},
    tickets = [],
    totalTickets = 0,
    withWin = false,
}) {
    /* если не tickets то надо прелоад */
    console.log(tickets);
    const addWinData = !!withWin;
    const winNumber = addWinData ? withWin.win_tickets : [];

    return (
        <div className={`tickets_list ${!addWinData ? "simp" : ""}`}>
            <div className="tickets-container">{renderTickets()}</div>
            {renderPagination()}
        </div>
    );

    function renderTickets() {
        if (!tickets.length)
            return <div className="no-tickets">No tickets</div>;

        return tickets.map((ticket, index) => {
            console.log(ticket);
            const {
                win_amount,
                win_numbers_count,
                win_ticket,
                picked_numbers,
            } = ticket;
            return (
                <div key={index} className="ticket">
                    <div className="ticket__numbers">
                        {renderNumbers(picked_numbers, withWin.win_numbers)}
                    </div>
                    {addWinData && (
                        <div className="ticket__win-info">
                            <div className="win-info__block">
                                {prettyAmount(win_amount, true)} coins
                            </div>
                        </div>
                    )}
                </div>
            );
        });

        function renderNumbers(numbers = [], winNumbers = false) {
            return numbers.map((num, index) => {
                const isWin = winNumbers ? winNumbers.includes(num) : false;
                return (
                    <div
                        key={index}
                        className={`ticket__number ${isWin ? "win" : ""}`}
                    >
                        {num}
                    </div>
                );
            });
        }
    }

    function renderPagination() {
        // << < 1 2 ... n-1 n > >>
        const { page, limit } = pagination;
        const currentPage = pagination.page + 1;
        const pages = Math.ceil(totalTickets / limit);

        console.log(pagination);
        console.log(currentPage);
        console.log(pages);

        const pagesToShow = 5;
        const pagesArr = [];

        // if (pages < 2) return null;

        if (pages <= pagesToShow) {
            for (let i = 1; i <= pages; i++) {
                // console.log("here");
                pagesArr.push(
                    <div
                        className={`page ${i === currentPage ? "active" : ""}`}
                        key={"page_" + i}
                        onClick={() => {
                            changePagination(i - 1);
                            // setPagination({ ...pagination, page: i })
                        }}
                    >
                        {i}
                    </div>
                );
            }
            // return pagesArr;
        } else {
            // pages > pagesToShow
            // 1 2 ... n-1 n
            if (currentPage < 3) {
                // 1 2 3 ... n-1 n
                for (let i = 1; i <= 3; i++) {
                    pagesArr.push(
                        <div
                            className={`page ${
                                i === currentPage ? "active" : ""
                            }`}
                            key={"page_" + i}
                            onClick={() => changePage(i - 1)}
                        >
                            {i}
                        </div>
                    );
                }
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage}>
                        ...
                    </div>
                );
                pagesArr.push(
                    <div
                        className={`page ${
                            pages === currentPage ? "active" : ""
                        }`}
                        key={"page_" + pages}
                        onClick={() => changePage(pages - 1)}
                    >
                        {pages}
                    </div>
                );
            } else if (currentPage > pages - 2) {
                // 1 2 ... n-2 n-1 n
                pagesArr.push(
                    <div
                        className={`page ${1 === currentPage ? "active" : ""}`}
                        key={"page_" + 1}
                        onClick={() => changePage(0)}
                    >
                        {1}
                    </div>
                );
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage}>
                        ...
                    </div>
                );
                for (let i = pages - 2; i <= pages; i++) {
                    pagesArr.push(
                        <div
                            className={`page ${
                                i === currentPage ? "active" : ""
                            }`}
                            key={"page_" + i}
                            onClick={() => changePage(i - 1)}
                        >
                            {i}
                        </div>
                    );
                }
            } else {
                // 1 ... n-1 n n+1 ... n
                pagesArr.push(
                    <div
                        className={`page ${1 === currentPage ? "active" : ""}`}
                        key={"page_" + 1}
                        onClick={() => changePage(0)}
                    >
                        {1}
                    </div>
                );
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage + "1"}>
                        ...
                    </div>
                );
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pagesArr.push(
                        <div
                            className={`page ${
                                i === currentPage ? "active" : ""
                            }`}
                            key={"page_" + i}
                            onClick={() => changePage(i - 1)}
                        >
                            {i}
                        </div>
                    );
                }
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage + "2"}>
                        ...
                    </div>
                );
                pagesArr.push(
                    <div
                        className={`page ${
                            pages === currentPage ? "active" : ""
                        }`}
                        key={"page_" + pages}
                        onClick={() => changePage(pages - 1)}
                    >
                        {pages}
                    </div>
                );
            }
        }

        console.log(pagesArr);
        const pagesArrLengthCheck = pagesArr.length < pagesToShow;
        return (
            <div className={`pagination ${pagesArrLengthCheck ? "small" : ""}`}>
                {!pagesArrLengthCheck && (
                    <>
                        <div
                            className="page arr db_arr"
                            onClick={() => changePage(0)}
                        >
                            <DoublePagArrSvg />
                        </div>
                        <div className="page arr" onClick={prevPage}>
                            <PagArrSvg />
                        </div>
                    </>
                )}
                {pagesArr}

                {!pagesArrLengthCheck && (
                    <>
                        <div className="page arr arr_r" onClick={nextPage}>
                            <PagArrSvg />
                        </div>
                        <div
                            className="page arr arr_r db_arr"
                            onClick={() => changePage(pages - 1)}
                        >
                            <DoublePagArrSvg />
                        </div>
                    </>
                )}
            </div>
        );

        function prevPage() {
            if (currentPage === 1) return;
            changePage(page - 1);
        }
        function nextPage() {
            if (currentPage === pages) return;
            changePage(page + 1);
        }
        function changePage(page) {
            changePagination(page);
        }
    }
}
