import { useEffect } from "react";
import useSocket from "../../hook/useSocket";
import Timer from "./Timer";
import { ReactComponent as StatBtnSvg } from "../../svg/stat_btn.svg";
import { ReactComponent as TicketSvg } from "../../svg/ticket.svg";
import io_path from "../../io_path";
import LastGame from "./LastGame";
import BiggestWin from "./BiggestWins";
import { useNavigate } from "react-router-dom";
import { prettyAmount } from "../../utils/helpers";

export default function WaitingRoom() {
    const { socket, gameRoom, gameSettings, gameInfo, myTickets } = useSocket();
    const navigate = useNavigate();

    useEffect(() => {
        //get biggest wins stat
        //get last game info
        // socket.emit('get biggest wins stat', {gameRoom})
        // socket.emit('get last game info', {gameRoom})
        socket.emit(io_path.GET_TICKETS, {
            fetch: false,
            // pagination: {
            //     limit: 10,
            //     page: 1,
            // },
            // game_id: "3b1319af828294c5",
        });
    }, [socket]);

    const game_id = gameSettings.settings?.outter_game_id || gameSettings.id;
    // console.log(gameRoom, gameSettings, gameInfo, myTickets);
    return (
        <div className="waiting_room">
            <div className="content">
                <div className="block">
                    <div className="lot_info">
                        <div className="row">
                            <h1>Big Lottery</h1>
                            <div className="id">ID: {game_id}</div>
                        </div>
                        <div className="row">
                            <div className="text">
                                Total tickets sold:
                                <span>{gameInfo.total_tickets_sold}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="text">
                                Users in game: <span>{gameRoom.length}</span>
                            </div>
                        </div>
                        <div className="jackpot">
                            <div className="text">Jackpot</div>
                            <div className="value">
                                {prettyAmount(gameInfo.jackpot, true)}
                            </div>
                        </div>
                    </div>
                    <Timer />
                    <div className="btns">
                        <button
                            onClick={() => {
                                navigate("./buy-tickets");
                            }}
                        >
                            <TicketSvg /> Buy ticket
                        </button>
                        <button
                            className="stat"
                            onClick={() => {
                                navigate("./my-stat");
                            }}
                        >
                            <StatBtnSvg />
                        </button>
                    </div>
                    {myTickets.total_tickets > 0 && (
                        <div className="my-tickets-block">
                            <p>
                                Total tickets purchased:{" "}
                                {myTickets.total_tickets}
                            </p>
                            <button
                                onClick={() => {
                                    if (myTickets.total_tickets) {
                                        navigate("./my-bought-tickets", {
                                            state: {
                                                game_id: gameSettings.id,
                                                total_tickets:
                                                    myTickets.total_tickets,
                                            },
                                        });
                                    }
                                }}
                            >
                                View all tickets
                            </button>
                        </div>
                    )}
                </div>
                <BiggestWin />
                <LastGame />
            </div>
        </div>
    );
}
