import { Route, Routes } from "react-router-dom";
import StartPoint from "./components";

function App() {
    return (
        <Routes>
            <Route path="/:gameID/:userToken/*" element={<StartPoint />} />
            {/* Waiting room + buy ticket + statistic */}
            <Route path="*" element={<h1>Not auth for game</h1>} />
        </Routes>
    );
}

export default App;
