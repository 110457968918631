export default {
    START_DRAW: "start_draw", // throw win balls
    ROOM_CONNECT: "room_connect", // connect to room
    ROOM_LEAVE: "room_leave", // leave room
    GAME_STATE: "game_state", // get state of game
    GAME_INFO: "game_info", // get info about game
    BIGGEST_WIN: "biggest_win", // get biggest win in game
    LAST_GAME_INFO: "last_game", // get biggest win in game
    GET_LAST_GAME_WINNERS: "get_last_game_winners", // get last game winners
    CHECK_BALANCE: "check_balance", // check balance

    GET_TOTAL_STAT: "get_total_stat", // check balance
    RECENT_GAMES: "recent_games", //
    ONE_RECENT_GAME_FULL_STAT: "one_recent_game_full_stat", //
    ONE_RECENT_GAME_ADDITIONAL_STAT: "one_recent_game_additional_stat", //

    BUY_TICKETS: "buy_tickets", // buy tickets
    TICKETS_BOUGHT: "tickets_bought", // tickets bought
    GET_TICKETS: "get_tickets", // get tickets
    GET_TICKETS_LIST: "get_tickets_list", // get tickets

    ASK_RESULT: "ask_result", // ask result
    USER_DATA: "user_data", // ask result
};
