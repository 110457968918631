export default () => {
    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener("message", (e) => {
        if (!e) return;
        let { command, time } = e.data;

        console.log("command is: ", command);
        console.log("time is: ", time);
        switch (command) {
            case "startTimer":
                /* eslint-disable-next-line no-restricted-globals */
                if (!self?.timer) {
                    // incrementTimer(1);
                    startAndReportTimer(time);
                } else {
                    console.log("Timer is already running");
                }
                break;
            case "stopTimer":
                /* eslint-disable-next-line no-restricted-globals */
                if (self?.timer) {
                    /* eslint-disable-next-line no-restricted-globals */
                    clearInterval(self.timer);
                    /* eslint-disable-next-line no-restricted-globals */
                    self.timer = false;
                    seconds = 0;
                    break;
                    // clearTimeout(self.timer);
                }
            default:
                console.log("default case");
                break;
        }
    });

    let seconds = 0;

    function incrementTimer(s = 1) {
        /* eslint-disable-next-line no-restricted-globals */
        self.timer = setInterval(() => {
            postMessage(seconds);
            seconds++;
        }, s * 1000);
    }

    function startAndReportTimer(time) {
        const interval = 1000; // Интервал в миллисекундах для секундомера
        const duration = time / interval; // Продолжительность таймера в секундах
        /* eslint-disable-next-line no-restricted-globals */
        if (!self.timer) {
            /* eslint-disable-next-line no-restricted-globals */
            self.timer = setInterval(() => {
                seconds++;
                postMessage({ type: "tick", time_left: duration - seconds });
                if (seconds >= duration) {
                    postMessage({ type: "done", message: "Timer finished" });
                    /* eslint-disable-next-line no-restricted-globals */
                    clearInterval(self.timer);
                    // close(); // Завершение работы Worker'а после окончания таймера
                }
            }, interval);
        }
    }
};
