import { ReactComponent as QuickSelSvg } from "../../../svg/quick_sel.svg";

export default function FillTickets({
    filledTickets,
    setFilledTickets,
    setFillType,
    buyTickets,
    setShowSettings,
}) {
    const allTicketsFilled = filledTickets.every(
        (ticket) => ticket.length >= 6
    );
    // console.log(filledTickets);
    return (
        <div className="fill_tickets">
            <div className="fill_head">
                <div
                    className="btn"
                    onClick={() => {
                        setFillType(true);
                        buyTickets(true);
                        setShowSettings(true);
                    }}
                >
                    <QuickSelSvg /> Quick selection
                </div>
                <div className="btn" onClick={clearAll}>
                    Clear All
                </div>
            </div>
            <div className="tickets-container">{renderTicketToFill()}</div>
            <button
                className={"bet " + (allTicketsFilled ? "active" : "")}
                onClick={() => {
                    if (allTicketsFilled) buyTickets(false);
                }}
            >
                Place a bet
            </button>
        </div>
    );

    function clearAll() {
        setFilledTickets(new Array(filledTickets.length).fill([]));
    }
    function renderTicketToFill() {
        return filledTickets.map((ticket, index) => {
            const ticketLength = ticket.length;
            const leftToSelect = 6 - ticketLength;
            const numbers = Array.from({ length: 49 }, (_, i) => i + 1);
            return (
                <div
                    key={index}
                    className={`ticket ${leftToSelect < 1 ? "filled" : ""}`}
                >
                    <div className="ticket__header">
                        <div className="ticket__header__title">
                            {leftToSelect < 1
                                ? `Ticket ${index + 1} - Filled`
                                : `Select ${leftToSelect} numbers`}
                        </div>
                    </div>
                    <div className="ticket__content">
                        {/* <div className="ticket__content__numbers"> */}
                        {numbers.map((number) => {
                            const isSelected = ticket.includes(number);
                            return (
                                <div
                                    key={number}
                                    className={`ticket__number ${
                                        isSelected ? "selected" : ""
                                    }`}
                                    onClick={() => {
                                        if (isSelected)
                                            return selectNumber(index, number);
                                        if (ticketLength >= 6) return;
                                        selectNumber(index, number);
                                    }}
                                >
                                    {number}
                                </div>
                            );
                        })}
                        {/* </div> */}
                    </div>
                </div>
            );
        });
    }

    function selectNumber(ticketIndex, number) {
        setFilledTickets((prev) => {
            const newTickets = [...prev];
            newTickets[ticketIndex] = newTickets[ticketIndex].includes(number)
                ? newTickets[ticketIndex].filter((num) => num !== number)
                : [...newTickets[ticketIndex], number];
            return newTickets;
        });
    }
}
