import { Route, Routes } from "react-router-dom";
import MyStat from "./MyStat";
import GamePage from "./GamePage";
import TicketsPage from "./TicketsPage";

export default function StatPage() {
    return (
        <Routes>
            <Route index element={<MyStat />} />
            <Route path=":history_game_id" element={<GamePage />} />
            <Route
                path=":history_game_id/my-tickets"
                element={<TicketsPage />}
            />
        </Routes>
    );
}
