import { useEffect, useState } from "react";
import useSocket from "../../hook/useSocket";
import { useNavigate } from "react-router-dom";
import io_path from "../../io_path";

export default function GameStart() {
    const INTERVAL_SHOW_TIME = 1500;

    const { gameSettings, gameInfo, meLikeUser, socket, gameRoom, myTickets } =
        useSocket();
    const navigate = useNavigate();
    const [drawState] = useState(() => {
        const start_draw = window.localStorage.getItem(
            "start_draw_" + gameSettings.id
        );
        // return true;
        if (start_draw) return true;
        return false;
    });
    const [showWinNums, setShowWinNums] = useState([]);

    useEffect(() => {
        if (!drawState) {
            setShowWinNums(gameSettings.win_nums);
        } else {
            showNumsBySteps();
        }

        // if (!myTickets.total_tickets && win_data?.totalWinningTickets > 0) {
        //     socket.emit(io_path.GET_TICKETS, { game_id: gameSettings.id });

        //     // socket.on(io_path.GET_TICKETS, (data) => {
        // }
    }, []);

    // useEffect(()=>{}, [myTickets])
    // console.log(myTickets);

    const { win_data = {} } = meLikeUser;

    useEffect(() => {
        if (win_data?.totalWinningTickets > 0 && !myTickets?.total_tickets) {
            // fetch every 3 sec while myTickets is empty or max 10 times

            let i = 0;
            const interval = setInterval(() => {
                if (myTickets?.total_tickets || i >= 10) {
                    clearInterval(interval);
                    return;
                }
                socket.emit(io_path.GET_TICKETS, { game_id: gameSettings.id });
                i++;
            }, 3000);
        }
    }, [win_data?.totalWinningTickets, myTickets?.total_tickets]);
    // console.log(gameSettings, gameInfo, drawState);
    // console.log("showWinNums:");
    // console.log(showWinNums);
    // console.log(meLikeUser);
    const showId = gameSettings.settings?.outter_game_id || gameSettings.id;
    const showDetails = showWinNums.length === gameSettings.win_nums.length;
    return (
        <div className="go_game">
            <div className="content">
                <div className="lottery_info">
                    <div className="row">
                        <h1>Big Lottery</h1>
                        <div className="id">ID: {showId}</div>
                    </div>
                    <div
                        className="row"
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        <div className="text">
                            Total tickets sold:
                            <span>{gameInfo.total_tickets_sold}</span>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="text">
                            Users in game: <span>{gameRoom.length}</span>
                        </div>
                    </div> */}
                    <div className="jackpot">
                        <div className="text">Jackpot</div>
                        <div className="value">{gameInfo.jackpot}</div>
                    </div>
                </div>
                <div className="lucky_numbers">
                    <div className="l_n_title">Lucky numbers</div>
                    <div className="container">{renderLuckyNumbers()}</div>
                </div>
                <div className="win-info">
                    <div className="block">
                        <div className="title">Winning ticket</div>

                        <div className={`value ${showDetails ? "" : "load"}`}>
                            {showDetails ? (
                                win_data?.totalWinningTickets ?? 0
                            ) : (
                                <div className="circle" />
                            )}
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">Your Prize:</div>
                        <div className={`value ${showDetails ? "" : "load"}`}>
                            {showDetails ? (
                                // win_data.totalWinAmount.toFixed(2) + " coins"
                                prettyWinPrise(win_data?.totalWinAmount ?? 0) +
                                " coins"
                            ) : (
                                <div className="circle" />
                            )}
                        </div>
                    </div>
                </div>

                <div className="tickets_btn_container">
                    <div className="text">
                        Total tickets purchased: {myTickets.total_tickets}
                    </div>
                    <button
                        className={showDetails ? "show" : ""}
                        onClick={() => {
                            if (myTickets.total_tickets && showDetails) {
                                navigate("./my-bought-tickets", {
                                    state: {
                                        game_id: gameSettings.id,
                                        total_tickets: myTickets.total_tickets,
                                        win: {
                                            win_numbers: gameSettings.win_nums, // used to highlight win numbers
                                            my_total_win:
                                                win_data?.totalWinAmount, // afff
                                            win_tickets:
                                                win_data?.totalWinningTickets, // afff
                                        },
                                    },
                                });
                            }
                        }}
                    >
                        View all tickets
                    </button>
                </div>
            </div>
        </div>
    );

    function prettyWinPrise(summ) {
        let pretty = summ;
        // если сумма имеет десятую часть - округляем до 2 знаков
        if (summ % 1 !== 0) {
            console.log(pretty);
            pretty = summ.toFixed(2);
        }
        //если десятая часть это 0 - убираем ее
        if (pretty % 1 === 0) {
            pretty = pretty.toString().replace(/\.\d+/, "");
        }

        return pretty;
        // if sum %
        //sum -> to fixed 2
    }

    function renderLuckyNumbers() {
        return gameSettings.win_nums.map((num, index) => {
            const show = index + 1 <= showWinNums.length;

            return (
                <div
                    className={`lucky_number ${show ? "show" : ""}`}
                    key={index}
                >
                    {show ? num : ""}
                </div>
            );
        });
    }

    function showNumsBySteps() {
        let i = 0;
        const interval = setInterval(() => {
            if (i >= gameSettings.win_nums.length) {
                clearInterval(interval);
                window.localStorage.removeItem("start_draw_" + gameSettings.id);
                return;
            }
            setShowWinNums((prev) => {
                const newItem = gameSettings.win_nums[prev.length];
                if (!newItem) return prev;
                return [...prev, newItem];
            });
            i++;
        }, INTERVAL_SHOW_TIME);
    }
}
