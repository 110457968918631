import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSocket from "../../../hook/useSocket";
import { ReactComponent as ArrowSvg } from "../../../svg/arrow.svg";
import io_path from "../../../io_path";

export default function SelectSettings({
    ticketsCount,
    setTicketsCount,

    fillType,
    setFillType,

    goBuyTickets,
    error,
    setError,
}) {
    const { socket, gameSettings, gameInfo, meLikeUser } = useSocket();
    const {
        settings: {
            tickets_per_user = null,
            ticket_cost = 10,
            total_tickets = null,
        },
    } = gameSettings;
    const { total_tickets_sold = 0 } = gameInfo;

    useEffect(() => {
        socket.emit(io_path.CHECK_BALANCE);
    }, []);
    useEffect(() => {
        if (ticketsCount > 100) setFillType(true);
    }, [ticketsCount]);

    const navigate = useNavigate();
    const leftBtn = checkDisableForBtn("left");
    const rightBtn = checkDisableForBtn("right");
    const inp = useRef(null);

    const fastBtns = [20, 50, 100];
    // console.log(meLikeUser);

    return (
        <div className="select_settings">
            <div className="head">
                <h1>Buy Tickets</h1>
                <div
                    className="close"
                    onClick={() => {
                        navigate("..", { replace: true });
                    }}
                />
            </div>
            <div className="settings">
                <div className="row">
                    <div
                        className="pick-number"
                        onClick={() => {
                            inp.current.focus();
                        }}
                    >
                        <button disabled={leftBtn} onClick={descTicketsCount}>
                            <ArrowSvg />
                        </button>
                        <input
                            ref={inp}
                            type="text"
                            placeholder="0"
                            value={ticketsCount}
                            onBlur={() => {
                                setTicketsCount(checkOnValidData(ticketsCount));
                            }}
                            onChange={handleChangeTicketsCount}
                        />
                        <button disabled={rightBtn} onClick={incTicketsCount}>
                            <ArrowSvg />
                        </button>
                    </div>
                    {renderFastBtns()}
                </div>

                <div className="title">
                    <h2>Select number:</h2>
                </div>
                <div className="row centr">
                    <div
                        className={`big-btn ${!fillType ? "active" : ""} ${
                            ticketsCount > 100 ? "disabled" : ""
                        }`}
                        data-val="false"
                        onClick={changeFillType}
                    >
                        <div className="check" /> Manually
                    </div>
                    <div
                        className={`big-btn ${fillType ? "active" : ""}`}
                        data-val="true"
                        onClick={changeFillType}
                    >
                        <div className="check" /> Automatically
                    </div>
                </div>
            </div>
            <div className="count">
                <h2>Total amount</h2>
                <div className="value">{getAmount()} coins</div>
            </div>
            <button className="buy" onClick={buyTickets}>
                Place a bet{" "}
            </button>
            {error ? <div className="error">{error}</div> : ""}
        </div>
    );

    function buyTickets() {
        const sum = getAmount();
        if (meLikeUser.balance < sum) {
            return setError("You don't have enough coins");
        }
        console.log(ticketsCount);
        if (ticketsCount < 1) return;

        goBuyTickets();
    }
    function getAmount() {
        return +ticketsCount * +ticket_cost;
    }

    function renderFastBtns() {
        return fastBtns.map((el) => {
            return (
                <div
                    className="fast-btn"
                    data-val={el}
                    onClick={setFastVal}
                    key={el}
                >
                    {el}
                </div>
            );
        });
    }

    function checkDisableForBtn(btnType = "left") {
        if (btnType === "left") {
            return +ticketsCount > 1 ? false : true;
        } else {
            if (tickets_per_user && tickets_per_user < +ticketsCount + 1)
                return true;
            if (total_tickets && total_tickets < +ticketsCount + 1) return true;
            return false;
        }
    }

    function setFastVal(e) {
        setTicketsCount(checkOnValidData(e.currentTarget.dataset.val));
    }

    function checkOnValidData(sum) {
        sum = Number(sum);
        if (total_tickets && total_tickets - total_tickets_sold < sum)
            return total_tickets - total_tickets_sold;
        if (tickets_per_user && tickets_per_user < sum) return tickets_per_user;
        if (sum < 1) return 1;
        return sum;
    }

    function incTicketsCount(e) {
        e.stopPropagation();
        setTicketsCount((prev) => {
            const newVal = +prev + 1;
            if (tickets_per_user && tickets_per_user < newVal) return prev;
            if (total_tickets && total_tickets - total_tickets_sold < newVal)
                return prev;
            return newVal;
        });
    }
    function descTicketsCount(e) {
        e.stopPropagation();
        if (ticketsCount > 1) setTicketsCount((prev) => +prev - 1);
    }

    function changeFillType(e) {
        if ((ticketsCount > 100) & (e.currentTarget.dataset.val === "false"))
            return;
        setFillType(e.currentTarget.dataset.val === "true");
    }

    function handleChangeTicketsCount({ target }) {
        if (error) setError(false);
        //check if value is number or empty
        if (!isNaN(target.value) || target.value === "") {
            setTicketsCount(target.value);
        }
    }
}
