import { Route, Routes } from "react-router-dom";
import GameStart from "./GameStart";
import MyBoughtTicketsList from "../Prepare/MyBoughtTicketsList";

export default function Game() {
    return (
        <Routes>
            <Route index element={<GameStart />} />
            <Route
                path="/my-bought-tickets"
                element={<MyBoughtTicketsList />}
            />
        </Routes>
    );
}
