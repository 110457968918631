import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Layouts/Loader";
import useSocket from "../../../../hook/useSocket";
import io_path from "../../../../io_path";
import TicketsList from "../../../Tickets/TicketsList";

export default function TicketsPage() {
    const [ticketsData, setTicketsData] = useState(null);
    const [pagination, setPagination] = useState({
        limit: 10,
        page: 0,
    });

    const { socket } = useSocket();
    const navigate = useNavigate();
    const { history_game_id = null } = useParams();
    const { state: locationState = null } = useLocation();
    /*
    locationState = {
        total_tickets: 100, // use in pagination
        win_numbers: [1, 2, 3, 4, 5, 6], // used to highlight win numbers
        my_total_win: 100, // afff
        win_tickets: 10, // afff
    }
    */

    // console.log(locationState);
    // console.log(history_game_id);

    useEffect(() => {
        if (!locationState) {
            navigate("../", { replace: true });
        }
        if (ticketsData && ticketsData[pagination.page]) return;

        socket.emit(io_path.GET_TICKETS_LIST, {
            pagination,
            game_id: history_game_id,
        });

        socket.on(io_path.GET_TICKETS_LIST, (data) => {
            setTicketsData((prev) => {
                if (!prev) return { [pagination.page]: data };
                prev[pagination.page] = data;
                return { ...prev };
            });
        });

        return () => {
            socket.off(io_path.GET_TICKETS_LIST);
        };
    }, [pagination, ticketsData]);
    console.log(ticketsData);

    if (!ticketsData) return <Loader />;

    return (
        <div className="tickets-page">
            <div className="content">
                <div className="p_head">
                    <h1
                        onClick={() => {
                            navigate("../", { replace: true });
                        }}
                    >
                        <div className="back_arrow" />
                        View all tickets
                    </h1>
                </div>

                <div className="win-info">
                    <div className="block">
                        <div className="title">Winning ticket</div>

                        <div className={`value`}>
                            {locationState.win_tickets}
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">Your Prize:</div>
                        <div className={`value `}>
                            {locationState.my_total_win}
                        </div>
                    </div>
                </div>
                <TicketsList
                    changePagination={changePagination}
                    pagination={pagination}
                    tickets={ticketsData[pagination.page]}
                    totalTickets={locationState.total_tickets}
                    withWin={{
                        ...locationState,
                    }}
                />
                {/*  передаем массив по пейджу из ticketsData  */}
            </div>
        </div>
    );

    function changePagination(page) {
        setPagination((prev) => {
            return { ...prev, page };
        });
    }
}
