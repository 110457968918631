import { useEffect, useState } from "react";
import useSocket from "../../hook/useSocket";
import io_path from "../../io_path";
import { ReactComponent as DoublePagArrSvg } from "../../svg/double_pag_arr.svg";
import { ReactComponent as PagArrSvg } from "../../svg/pag_arr.svg";
import { prettyAmount } from "../../utils/helpers";

export default function LastGame() {
    const [lastGameData, setLastGameData] = useState(null);
    const { socket, gameRoom, gameSettings, gameInfo } = useSocket();
    useEffect(() => {
        socket.emit(io_path.LAST_GAME_INFO);
        socket.on(io_path.LAST_GAME_INFO, (data) => {
            setLastGameData(data);
            // console.log(data);
        });

        return () => {
            socket.off(io_path.LAST_GAME_INFO);
        };
    }, [socket]);

    // console.log(lastGameData);

    const game_id = lastGameData?.game_outer_id || lastGameData?.game_id || "#";
    if (lastGameData === false)
        return (
            <div className="block last_game">
                <div className="row">
                    <h2>No Last Lottery Info</h2>
                    {/* <div className="id">id: {game_id}</div> */}
                </div>
            </div>
        );
    if (!lastGameData) return <LoadingSkeleton />;
    // console.log(lastGameData);
    return (
        <div className="block last_game">
            <div className="row">
                <h2>
                    Last Lottery ID: <span>{game_id}</span>
                </h2>
                {/* <div className="id">id: {game_id}</div> */}
            </div>
            <RenderWinTable
                total_users={lastGameData.winners_count}
                game_id={lastGameData?.game_id}
            />
        </div>
    );
}

function RenderWinTable({ total_users = 0, game_id = null }) {
    const { socket } = useSocket();

    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const [winUsers, setWinUsers] = useState([]);
    useEffect(() => {
        if (total_users) {
            socket.emit(io_path.GET_LAST_GAME_WINNERS, {
                page: pagination.page,
                perPage: pagination.perPage,
                total: total_users,
                game_id: game_id,
            });
            socket.on(io_path.GET_LAST_GAME_WINNERS, (data) => {
                setWinUsers(data);
            });

            return () => {
                socket.off(io_path.GET_LAST_GAME_WINNERS);
            };
        }
    }, [pagination]);
    // console.log("winUsers:");
    // console.log(winUsers);

    return (
        <div className="win-table">
            <div className="table">
                <div className="t_row t_row_head">
                    {/* <div className="cell">Place</div> */}
                    <div className="cell">User</div>
                    <div className="cell">Win</div>
                </div>
                <div className="t-body">
                    {winUsers.length === 0 && (
                        <div className="t_row t_row_body">
                            <div className="cell">No winners</div>
                        </div>
                    )}
                    {winUsers.map((user, i) => (
                        <div className="t_row t_row_body" key={i}>
                            {/* <div className="cell">{i + 1}</div> */}
                            <div className="cell">{user.nick}</div>
                            <div className="cell">
                                {prettyAmount(user.win_amount)} coins
                            </div>
                        </div>
                    ))}
                </div>
                {renderPagination()}
            </div>
        </div>
    );

    function renderPagination() {
        // << < 1 2 ... n-1 n > >>

        const pages = Math.ceil(total_users / pagination.perPage);
        const currentPage = pagination.page;
        const pagesToShow = 5;
        const pagesArr = [];
        // need to be 2 arrow btns left + 2 btns right and center if pages > 5 separate by ...
        // if (pages <= 1) return "";
        if (pages <= pagesToShow) {
            for (let i = 1; i <= pages; i++) {
                pagesArr.push(
                    <div
                        className={`page ${i === currentPage ? "active" : ""}`}
                        key={"page_" + i}
                        onClick={() =>
                            setPagination({ ...pagination, page: i })
                        }
                    >
                        {i}
                    </div>
                );
            }
            // return pagesArr;
        } else {
            // pages > pagesToShow
            // 1 2 ... n-1 n
            if (currentPage < 3) {
                // 1 2 3 ... n-1 n
                for (let i = 1; i <= 3; i++) {
                    pagesArr.push(
                        <div
                            className={`page ${
                                i === currentPage ? "active" : ""
                            }`}
                            key={"page_" + i}
                            onClick={() => changePage(i)}
                        >
                            {i}
                        </div>
                    );
                }
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage}>
                        ...
                    </div>
                );
                pagesArr.push(
                    <div
                        className={`page ${
                            pages === currentPage ? "active" : ""
                        }`}
                        key={"page_" + pages}
                        onClick={() => changePage(pages)}
                    >
                        {pages}
                    </div>
                );
            } else if (currentPage > pages - 2) {
                // 1 2 ... n-2 n-1 n
                pagesArr.push(
                    <div
                        className={`page ${1 === currentPage ? "active" : ""}`}
                        key={"page_" + 1}
                        onClick={() => changePage(1)}
                    >
                        {1}
                    </div>
                );
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage}>
                        ...
                    </div>
                );
                for (let i = pages - 2; i <= pages; i++) {
                    pagesArr.push(
                        <div
                            className={`page ${
                                i === currentPage ? "active" : ""
                            }`}
                            key={"page_" + i}
                            onClick={() => changePage(i)}
                        >
                            {i}
                        </div>
                    );
                }
            } else {
                // 1 ... n-1 n n+1 ... n
                pagesArr.push(
                    <div
                        className={`page ${1 === currentPage ? "active" : ""}`}
                        key={"page_" + 1}
                        onClick={() => changePage(1)}
                    >
                        {1}
                    </div>
                );
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage + "1"}>
                        ...
                    </div>
                );
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pagesArr.push(
                        <div
                            className={`page ${
                                i === currentPage ? "active" : ""
                            }`}
                            key={"page_" + i}
                            onClick={() => changePage(i)}
                        >
                            {i}
                        </div>
                    );
                }
                pagesArr.push(
                    <div className="sep" key={"sep_" + currentPage + "2"}>
                        ...
                    </div>
                );
                pagesArr.push(
                    <div
                        className={`page ${
                            pages === currentPage ? "active" : ""
                        }`}
                        key={"page_" + pages}
                        onClick={() => changePage(pages)}
                    >
                        {pages}
                    </div>
                );
            }
        }

        const pagesArrLengthCheck = pagesArr.length < pagesToShow;
        return (
            <div className={`pagination ${pagesArrLengthCheck ? "small" : ""}`}>
                {!pagesArrLengthCheck && (
                    <>
                        <div
                            className="page arr db_arr"
                            onClick={() => changePage(1)}
                        >
                            <DoublePagArrSvg />
                        </div>
                        <div className="page arr" onClick={prevPage}>
                            <PagArrSvg />
                        </div>
                    </>
                )}
                {pagesArr}

                {!pagesArrLengthCheck && (
                    <>
                        <div className="page arr arr_r" onClick={nextPage}>
                            <PagArrSvg />
                        </div>
                        <div
                            className="page arr arr_r db_arr"
                            onClick={() => changePage(pages)}
                        >
                            <DoublePagArrSvg />
                        </div>
                    </>
                )}
            </div>
        );

        function nextPage() {
            const next =
                pagination.page + 1 > pages ? pages : pagination.page + 1;
            setPagination({ ...pagination, page: next });
        }
        function prevPage() {
            const prev = pagination.page - 1 < 1 ? 1 : pagination.page - 1;
            setPagination({ ...pagination, page: prev });
        }
        function changePage(page) {
            setPagination({ ...pagination, page });
        }
    }
}

function LoadingSkeleton() {
    return (
        <div className="block last_game">
            <div className="row">
                <h2>Last Lottery</h2>
                <div className="id">id: #</div>
            </div>
        </div>
    );
}
