// import crypto from "crypto-js";

export function prettyTime(time = 0) {
    //time in ms
    //return {hours: '00', minutes: '00', seconds: '00'}
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return {
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
    };
}
export function prettyAmount(amount = 0, rounding = false) {
    if (!amount) return 0;

    if (rounding) {
        return Math.round(amount);
    }
    return amount.toFixed(2);
}
export function getRandomNums(n = 5, min = 1, max = 10) {
    // if (max - min + 1 < n) {
    //     throw new Error(
    //         "Диапазон значений недостаточен для генерации уникальных чисел"
    //     );
    // }
    // const uniqueNums = new Set();
    // while (uniqueNums.size < n) {
    //     const byte = crypto.randomBytes(1)[0];
    //     const num = (byte % (max - min + 1)) + min;
    //     uniqueNums.add(num);
    // }
    // return Array.from(uniqueNums);
}
